<template>
  <div class="money_title">
    <div class="hint">
      优惠提醒：
      <i class="el-icon-info" style="color: #ff8a00" />
      {{ activityPeriod }} 期间
    </div>
    <div class="margin-top-m" >
      <div >限时充值优惠：</div>
      <div class="flex" v-for="item in list">
        <div class="margin-right-m">
          <span class="label">充值满</span>
          <span class="txt">{{ item.rechargeAmount | currency }}  ,</span>
        </div>
        <div v-if="mode === 'linear'">
          <span class="label">赠送充值金额的</span>
          <span class="txt">{{ item.presentPercent || 0 }} %</span>
        </div>
        <div v-else>
          <span class="label">赠送</span>
          <span class="text-orange txt">
            {{ freeMoney(item.rechargeAmount) }}
          </span>
          <span class="label">元代金额</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { calculatePresentAmount } from "../utils";
export default {
  props: {
    activityPeriod: {
      type: String,
    },
    mode: {
      type: String,
    },
    list: {
      type: Array,
    },
  },
  methods: {
    freeMoney(value) {
      return calculatePresentAmount(value, {
        items: this.list,
        mode: this.mode,
      });
    },
  },
};
</script>