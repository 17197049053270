<template>
  <div class="add_money_box">
    <div class="money_box_wrap">
      <div v-show="pay_status">
        <Notice
          :activityPeriod="activityPeriod"
          :mode="money_list.mode"
          :list="money_list.items"
          v-if="discounts"
        />
        <div class="balance">
          <span class="tag">账户余额：</span>
          <span class="info">{{ money_out | currency }}</span>
        </div>
        <div class="add_num">
          <div class="flex">
            <a
              class="
                flex
                align-center
                solid-top solid-left solid-right
                padding-lr-xs padding-tb-m
              "
              @click="wx_pay = true"
              :style="`border: 1px solid ${
                !wx_pay ? 'transparent' : '#39b54a'
              }`"
            >
              <span class="iconfont iconwxzhifu text-green" />
              <span class="margin-left-m">微信支付充值</span>
            </a>
            <a
              class="
                flex
                align-center
                solid-top solid-right
                padding-lr-xs padding-tb-m
              "
              @click="wx_pay = false"
              :style="`border: 1px solid ${
                wx_pay ? 'transparent' : '#39b54a'
              }`"
            >
              <svg viewBox="0 0 1024 1024" width="15">
                <path
                  d="M929.88416 106.042368 92.023808 106.042368c-50.601984 0-92.024832 41.201664-92.024832 91.56096l0 102.294528 1022.019584 0 0-102.294528C1022.019584 147.244032 980.55168 106.042368 929.88416 106.042368z"
                  p-id="2937"
                  fill="#39b54a"
                ></path>
                <path
                  d="M0 822.295552c0 50.357248 41.422848 91.583488 92.024832 91.583488l837.860352 0c50.666496 0 92.135424-41.22624 92.135424-91.583488L1022.020608 437.238784 0 437.238784 0 822.295552zM836.009984 686.948352l75.757568 0 0 86.171648-75.757568 0L836.009984 686.948352zM738.59584 686.948352l75.712512 0 0 86.171648-75.712512 0L738.59584 686.948352zM645.22752 686.948352l75.78112 0 0 86.171648-75.78112 0L645.22752 686.948352z"
                  p-id="2938"
                  fill="#39b54a"
                ></path>
              </svg>
              <span class="margin-left-m">线下汇款充值</span>
            </a>
          </div>
          <div class="num_list" v-if="wx_pay">
            <div class="add_money_input clr">
              <div class="up_tag fl">充值金额：</div>
              <div class="fl">
                <div class="num_btn clr">
                  <div class="btn_li fl">
                    <el-button type="text" class="fwb"></el-button>
                  </div>
                </div>
                <div class="moeny_input clr">
                  <div class="fl">
                    <el-input
                      placeholder="请输入充值金额"
                      clearable
                      v-model="money"
                      onkeyup="this.value=/^\d+\.?\d{0,2}$/.test(this.value) ? this.value : this.value.substring(0,this.value.length-1)"
                    >
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                  <div class="span fl hint" v-show="present > 0.01">
                    <span class="margin-left-sm">赠送金额：</span>
                    {{ present | currency }}
                  </div>
                </div>
              </div>
            </div>
            <div class="chenk_btn">
              <el-checkbox v-model="checked">
                <span class="declare"
                  >我已了解：充值金额包括赠送金额将进入账户余额，不可提现。</span
                >
              </el-checkbox>
              <span class="more">
                <a
                  href="https://www.shihaishibiao.com/docs/contract.html#_3-%E8%B4%A6%E6%88%B7%E4%BD%99%E9%A2%9D"
                  target="_blank"
                >
                  <el-button type="text">了解详情</el-button>
                </a>
              </span>
            </div>
            <div class="money_btn">
              <el-button
                type="primary"
                @click="add_money"
                v-if="checked && parseFloat(money)"
                >立即充值</el-button
              >
              <el-button type="info" v-else>立即充值</el-button>
            </div>
          </div>
          <div class="num_list" v-else>
            <div class="autopay">
              <div class="hint">
                <h4>温馨提示：</h4>
                <p>
                  1、受银行处理时间影响，采用外汇或者线下汇款方式到账会有延误，
                  <b class="txt">强烈建议采用微信支付实时到账。</b>
                </p>
                <p>
                  2、线下汇款直接向士海食标的对公账户汇款，在汇款用途一栏请填写
                  <b class="txt">“士海食标账户充值”</b
                  >。各种方式的到账时间一般为：同行1-2天，跨行3-5天（具体到账时间以银行的实际到账时间为准）。
                </p>
                <p>
                  3、为了能及时的完成账户充值，请您在汇款前在完成士海食标用户的注册以及企业信息的认证。
                </p>
                <p>
                  4、请避免北京时间21：00-00：00进行汇款，否则受银行出账时间影响，可能出现延迟一天到账情况。
                </p>
              </div>
              <h4>
                请您通过网银转账，或者自行到银行进行汇款，汇款账号信息如下：
              </h4>
              <div class="numbe">
                <p>公司名称：士海食标（广州）技术服务有限公司</p>
                <p>开户银行：中国建设银行股份有限公司广州天河东路支行</p>
                <p>开户账号：44050158050100001913</p>
              </div>
              <el-tag class="btn_send" type="success"
                >转账成功后请您联系客服进行充值处理</el-tag
              >
            </div>
          </div>
        </div>
        <div class="pop_code" v-show="pop">
          <div class="pop_box_wrap">
            <div class="pop_box">
              <div class="title">
                <div class="name">
                  <h3><span class="iconfont iconwxzhifu"></span>微信支付</h3>
                </div>
                <el-button
                  @click="off_pop"
                  class="title_btn"
                  type="text"
                  icon="el-icon-close"
                ></el-button>
              </div>
              <div class="code_text">
                应付金额：
                <span class="money">{{ money | currency }}</span>
              </div>
              <div class="code_img">
                <div id="qrcode" class="dib" ref="qrcode"></div>
              </div>
              <div class="wx_pay">
                <img src="/static/img/wx.png" alt />
              </div>
            </div>
          </div>
        </div>
        <div class="pop_bg" v-show="pop"></div>
      </div>

      <div class="succeed_wrap" v-show="!pay_status">
        <div class="icon_list">
          <el-alert
            title="恭喜您，充值成功！"
            type="success"
            :description="
              '您已成功为 ' +
              ((user.company && user.company.companyName) || user.nickname) +
              ' 的账户余额进行充值'
            "
            :closable="false"
            show-icon
          ></el-alert>
        </div>
        <el-card class="box-card">
          <el-form label-width="150px" size="mini">
            <el-form-item label="充值金额：">
              <span>{{ parseFloat(money).toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="活动赠送金额：">
              <span>{{ (discounts ? present : 0.0) | currency }}</span>
            </el-form-item>
            <el-form-item label="当前账户余额：">
              <span>{{ money_out | currency }}</span>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            size="medium"
            @click="$router.push('/money')"
            >查看账户详情</el-button
          >
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import Notice from "./add_money/components/notice";
import { moment_set, limitFloat, time_range_disp } from "@/assets/js/validate";
import QRCode from "qrcodejs2";
import { mapGetters } from "vuex";
import { ceil } from "lodash";
import { calculatePresentAmount } from "./add_money/utils";
export default {
  components: { Notice },
  data() {
    return {
      pay_status: true,
      money: "",
      checked: false,
      money_list: {
        balance: 0,
        items: [],
      },
      money_out: 0,
      poll_status: null,
      pay_id: "",
      discounts: false,
      pop: false,
      wx_pay: true,
    };
  },
  watch: {
    money(e) {
      if (!/^d*(?:.d{0,2})?$/.test(e)) {
        this.value = "";
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
    present() {
      if (!this.discounts) return 0;
      // let num_step = 0;
      // for (const item of this.money_list.items) {
      //   if (this.money >= item.rechargeAmount) {
      //     const amount = ceil(this.money / (100 / item.presentPercent), 2);
      //     num_step = amount;
      //   }
      // }
      return calculatePresentAmount(this.money, this.money_list);
    },
    activityPeriod() {
      return time_range_disp(this.money_list.statr, this.money_list.end);
    },
  },
  created() {
    clearTimeout(this.poll_status);
    this.money_state();
    this.get_money();
  },
  methods: {
    freeMoney(value) {
      return calculatePresentAmount(value, {
        items: this.list,
        mode: this.mode,
      });
    },

    money_state() {
      this.$api.promo().then((res) => {
        this.money_list = {
          statr: res.data.startTime,
          end: res.data.endTime,
          balance: "",
          mode: res.data.mode,
          items: res.data.items,
        };
        this.discounts =
          this.nowInDateBetwen(
            moment_set(res.data.startTime),
            moment_set(res.data.endTime)
          ) && res.data.enabled;
      });
    },
    get_money(e) {
      //  获取余额
      let that = this;
      this.$api.account().then((res) => {
        this.money_list.balance = res.data.balance;
        this.money_out = res.data.balance;
        if (e === 0) {
          that.pay_status = false;
        }
      });
    },
    async add_money() {
      let that = this;
      clearTimeout(that.poll_status);
      that.poll_status = null;
      document.getElementById("qrcode").innerHTML = "";
      if (!this.money) return this.$message.warning("请输入充值金额");
      let info = {
        rechargeAmount: parseFloat(this.money),
        presentAmount: this.present || 0,
      };
      const { data } = await this.$api.account_post(info);
      that.pay_id = data.out_trade_no;
      that.$QRCodeSC(data.code_url);
      that.pop = true;
      if (that.pay_id) that.poll();
      that.get_money();
    },
    // 轮询
    poll() {
      let that = this;
      that.$api.get_pay(that.pay_id).then((res) => {
        if (res.data.status === 2) {
          that.$message.success("恭喜您，账户充值成功~");
          that.get_money(0);
          clearTimeout(that.poll_status);
        } else {
          that.poll_status = setTimeout(() => {
            that.poll();
          }, 1000);
        }
      });
    },
    nowInDateBetwen(d1, d2) {
      let dateBegin = new Date(d1); //将-转化为/，使用new Date
      let dateEnd = new Date(d2); //将-转化为/，使用new Date
      let dateNow = new Date(); //获取当前时间
      let beginDiff = dateNow.getTime() - dateBegin.getTime(); //时间差的毫秒数
      let beginDayDiff = Math.floor(beginDiff / (24 * 3600 * 1000)); //计算出相差天数
      let endDiff = dateEnd.getTime() - dateNow.getTime(); //时间差的毫秒数
      let endDayDiff = Math.floor(endDiff / (24 * 3600 * 1000)); //计算出相差天数
      if (endDayDiff < 0) return false;
      if (beginDayDiff < 0) return false;
      return true;
    },
    off_pop() {
      this.pop = false;
      clearTimeout(this.poll_status);
    },
  },
  destroyed() {
    clearTimeout(this.poll_status);
    this.poll_status = null;
  },
};
</script>

<style lang="scss" scoped>
.add_money_box {
  margin-top: 20px;
  .money_title {
    padding: 20px;
    display: inline-block;
    padding-right: 8%;
    box-shadow: 0px 1px 12px #e8dac9;
    .money_msg {
      margin-top: 10px;
      .title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
    .info {
      margin-left: 2em;
      display: flex;
      line-height: 1.8;
      .add {
        width: 165px;
      }
      .txt {
        color: #ff8a00;
        font-size: 16px;
        font-weight: bold;
      }
      .label {
        margin: 0 10px;
        color: #6c6c6c;
      }
    }
  }
  .money_box_wrap {
    padding: 30px;
    background-color: #fff;
  }
  .balance {
    margin: 20px 0;
    .info {
      color: #ff5050;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .add_num {
    .up_title {
      position: relative;
      z-index: 5;
      border: 1px solid rgba(228, 228, 228, 1);
      padding: 5px 12px;
      border-bottom: 0;
      background: #ffffff;
      bottom: -1px;
      .iconfont {
        color: #44b549;
        font-size: 16px;
        vertical-align: middle;
      }
      .txt {
        vertical-align: middle;
        font-size: 14px;
      }
    }
    .num_list {
      padding: 0 12px;
      position: relative;
      z-index: 2;
      border: 1px solid rgba(228, 228, 228, 1);
      font-size: 15px;
      .add_money_input {
        height: 105px;
        overflow: hidden;
        .up_tag {
          margin-right: 20px;
          line-height: 140px;
          vertical-align: middle;
        }
        .num_btn {
          .btn_li {
            line-height: 50px;
            margin-right: 17px;
          }
        }
        .moeny_input {
          .hint {
            line-height: 40px;
            color: #ff8a00;
            font-weight: bold;
          }
        }
      }
      .autopay {
        p {
          font-size: 13px;
        }
        .hint {
          padding: 13px 0;
        }
      }
      .numbe {
        width: 50%;
        padding: 20px;
        border: 1px #f2c811 dashed;
        p {
          font-size: 14px;
          font-weight: bold;
        }
      }
      .btn_send {
        margin-bottom: 20px;
        margin-top: 5px;
      }
    }
    .chenk_btn {
      line-height: 30px;
      vertical-align: middle;
      margin-bottom: 10px;
      .declare {
        color: #000000;
      }
      .more {
        text-align: left;

        .el-button {
          color: #3292ff;
        }
      }
    }
    .money_btn {
      margin-bottom: 20px;
      .el-button {
        color: #000000;
      }
    }
  }
  .pop_bg {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 2005;
  }
  .pop_code {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    z-index: 2006;

    .pop_box {
      display: inline-block;
      width: 380px;
      padding-bottom: 10px;
      vertical-align: middle;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #ebeef5;
      font-size: 18px;
      -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      text-align: left;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      padding-bottom: 30px;
      .title {
        position: relative;
        padding: 15px;
        padding-bottom: 10px;
        .name {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding-left: 0;
          margin-bottom: 0;
          font-size: 18px;
          line-height: 1;
          color: #303133;
          line-height: 30px;
        }
        .title_btn {
          position: absolute;
          top: 5px;
          right: 5px;
          padding: 0;
          border: none;
          outline: none;
          background: transparent;
          font-size: 16px;
          padding: 15px;
          cursor: pointer;
        }
        .iconfont {
          font-size: 30px;
          color: #09ba07;
          line-height: 30px;
          display: inline-block;
          vertical-align: top;
          padding-right: 15px;
        }
      }
      .code_img {
        padding-left: 27px;
        padding-right: 27px;
        text-align: center;
        position: relative;
        padding: 10px 15px;
        font-size: 0;
        line-height: 0;
      }
      .code_text {
        text-align: center;
        font-size: 16px;
        .money {
          font-weight: bold;
        }
      }
      .wx_pay {
        font-size: 0;
        line-height: 0;
        text-align: center;
        width: 200px;
        margin: auto;
        img {
          width: 100%;
          text-align: center;
        }
      }
    }
    .pop_box_wrap {
      display: inline-block;
      margin: auto;
    }
  }
  .pop_code:after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
}
</style>
