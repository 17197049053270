import _ from "lodash";
/**
 * 计算充值赠送金额
 * @param {number} rechargeAmount 充值金额
 * @param {object} activity 后端返回的整个活动数据
 * @param {string} activity.mode 活动类型
 * @param {object[]} activity.items 活动数组
 */
export function calculatePresentAmount(rechargeAmount, activity) {
    if (!activity) return 0; //没有生效的活动
    let mode = activity.mode;
    let items = _.filter(activity.items, (t) => !t.isDeleted); //过滤掉已删除的
    //查找对应的优惠项
    items = _.sortBy(items, (t) => -t.rechargeAmount); //倒序
    let item = _.find(items, (t) => rechargeAmount >= t.rechargeAmount); //找出第一个充值金额大于设置的金额
    if (!item) return 0;

    let basePrice = 0;
    let discount = 0;
    if (mode == "step") {
        basePrice = item.rechargeAmount;
        discount = item.presentPercent;
    } else if (mode == "linear") {
        basePrice = rechargeAmount;
        discount = item.presentPercent;
    }

    return _.round((basePrice * discount) / 100, 2); //计算出赠送金额
}